import React from "react";

import { PAGES, PATHS } from "../../index";

import { Link } from "../../../components/Actionable/";
import { RoundedBorder } from "../../../components/Svgs";
import { JsonBlock } from "../../../components/Typography";
import BodyParagraph from "../../../components/Typography/BodyParagraph";
import MessageCard from "../../../components/Typography/MessageCard";
import { sanitisePath } from "../../../helpers/strings";
import { ERROR_HANDLING as AUTH_ERROR_HANDLING } from "../auth/sections";
import getAvailableEndpoints from "../helpers/page";

export const INTRODUCTION = {
  summary: "Introduction",
  body: (
    <>
      <BodyParagraph>
        The first step to be able to send shipments is to provide applicable
        shipping data to DPD Web Services via either the Create domestic
        shipment API, Create International shipment API & Create collect on
        delivery shipment API.
      </BodyParagraph>
      <BodyParagraph>
        Which API that needs to be called depends on the type of shipment you
        would like to create, Please see a brief description for each API below
        to aid with this:
      </BodyParagraph>
      <BodyParagraph.Header>Create Domestic shipment API</BodyParagraph.Header>
      <BodyParagraph>
        Domestic shipments can be created with the Create domestic shipment API.
        This allows you to create shipments for delivery in the UK, Offshore
        location and the Republic of Ireland , the API includes the ability to
        pass in the required parcel customs data for shipments to the Republic
        of Ireland.
      </BodyParagraph>
      <BodyParagraph.Header>
        Create International shipment API
      </BodyParagraph.Header>
      <BodyParagraph>
        International shipments (excluding the Republic of Ireland) can be
        created with the Create international shipment API. This allows you to
        create shipments for delivery to the EU and the rest of the world and
        includes the ability to pass in the required parcel customs data.
      </BodyParagraph>
      <BodyParagraph>
        International shipments can be sent by Air and/or Road depending on the
        destination country.
        <br />
        Where required, full parcel customs data can be specified.
      </BodyParagraph>
      <BodyParagraph.Header>
        Create collect on delivery shipment API
      </BodyParagraph.Header>
      <BodyParagraph>
        Where you have shipments that need to be collected from the same address
        when a shipment is delivered you can use the Create collect on delivery
        API to create this type of shipment ( ie. Swap it / Reverse It
        Services).
      </BodyParagraph>
      <BodyParagraph>
        In order to create a shipment several helper methods have been created
        which aid creation of a shipment which their definitions are below.
      </BodyParagraph>
      <BodyParagraph.Header>Get list of countries</BodyParagraph.Header>
      <BodyParagraph>
        Returns list of all shipping destination countries and returns
        applicable data such as isoCode, Postcode Pattern, isPostcodeRequired.
      </BodyParagraph>
      <BodyParagraph.Header>Get country by country code</BodyParagraph.Header>
      <BodyParagraph>
        Returns country data for a specified country code such as isoCode,
        Postcode Pattern, isPostcodeRequired.
      </BodyParagraph>
      <BodyParagraph.Header>
        Validate outbound services for address
      </BodyParagraph.Header>
      <BodyParagraph>
        Returns service availability for outbound shipments for the shipment
        parameters provided (Given collection address / delivery address).
      </BodyParagraph>
      <BodyParagraph.Header>
        Validate inbound services for address
      </BodyParagraph.Header>
      <BodyParagraph>
        Returns service availability for inbound shipments for the shipment
        parameters provided (Given collection address / delivery address) for
        use with Swapit / Reverse It services.
      </BodyParagraph>
      <BodyParagraph.Header>Validate commodity code</BodyParagraph.Header>
      <BodyParagraph>
        Validates if the given commodity code is valid for the given delivery
        country.
      </BodyParagraph>
      <BodyParagraph.Header>
        Get network by geoservice code
      </BodyParagraph.Header>
      <BodyParagraph>
        Get network details for the given geo service code, returning
        description for service and country availability.
      </BodyParagraph>
      <BodyParagraph.Header>
        Get label by shipment id - Returns the labels for the given shipment id
      </BodyParagraph.Header>
      <BodyParagraph>
        Once a shipment has been created this API is available to pass the
        shipmentId returned from the insert shipment API to return the requested
        label output.
      </BodyParagraph>
      <BodyParagraph>
        Explore the technical documentation for our available endpoints below:
      </BodyParagraph>
      <BodyParagraph>{getAvailableEndpoints(PAGES.SHIPPING)}</BodyParagraph>
    </>
  ),
};

export const FLOW_INTEGRATIONS = {
  summary: "Integration",
  body: (
    <>
      <MessageCard
        messageType={"note"}
        message={
          <div style={{ wordWrap: "break-word" }}>
            In order to use Shipping endpoints there is a prerequisite to have
            integrated with DPD UK authentication API’s in order to authenticate
            with Shipping API’s. Please refer to our{" "}
            <Link to={`/${PAGES.AUTH}${PATHS.INTRODUCTION}`}>
              auth specification
            </Link>{" "}
            for more information.
          </div>
        }
      />
      <BodyParagraph>
        Example flow to access our reference helper data:
      </BodyParagraph>
      <div className="container mx-auto px-4 sm:px-6 md:px-8">
        <RoundedBorder>
          <img
            src={"/images/svg/ShippingReferenceFlow.svg"}
            alt={"Shipping Reference Flow"}
          />
        </RoundedBorder>
      </div>
      <BodyParagraph>
        Example flow to create a shipment and label:
      </BodyParagraph>
      <div className="container mx-auto px-4 sm:px-6 md:px-8">
        <RoundedBorder>
          <img src={"/images/svg/ShippingFlow.svg"} alt={"Shipping Flow"} />
        </RoundedBorder>
      </div>
    </>
  ),
};
export const TESTING_INFORMATION = {
  summary: "Testing Overview",
  body: (
    <>
      <BodyParagraph>
        A formal sign-off{" "}
        <b>
          <u>must</u>
        </b>{" "}
        be obtained from the Customer Automation Technical Team before live
        shipping is accepted. Once we have successfully signed off your labels
        and Insert Shipment JSON (if applicable) we will then require one final
        test pack containing the <b>LIVE</b> details (if not already present
        when submitted).
      </BodyParagraph>
      <BodyParagraph>
        DPD/DPD Local may revoke the authorisation to ship if subsequent
        problems arise with data integrity or label quality.
      </BodyParagraph>
      <MessageCard
        messageType="warning"
        message=" Please be aware not to use sensitive customer details whilst within the testing process, We highly recommend leaving any notification details blank or populating with your own details."
      />
      <MessageCard
        messageType="note"
        message="Whilst testing your API implementation within the Sandbox environment no billing of parcels will take place as physical parcels are not shipped."
      />
      <BodyParagraph>
        Once sign off is obtained It is recommended that the first live runs be
        of small volumes, and monitored by both DPD/DPD Local and the client.
      </BodyParagraph>
    </>
  ),
};

export const CREATE_SHIPMENT_GUIDANCE = {
  summary: "Create Shipment",
  body: (
    <>
      <BodyParagraph>
        As mentioned earlier in this section several reference helper APIs have
        been made available which will aid in successful creation of a shipment
        via Shipment API’s.
      </BodyParagraph>
      <MessageCard
        messageType="note"
        message="Depending on the type of account you hold (DPD or DPD Local) there may be a requirement to amend the data in the example requests given as these will possess details in relation to a DPD account."
      />
      <MessageCard
        messageType="example"
        message="If a DPD Local account is held you will need to amend “networkCode” within the Create Shipment requests as the network code provided in the example relates to a DPD networkCode and will not work and provide an error if a DPD Local Account is held."
      />
      <BodyParagraph.Header>Choosing the correct API</BodyParagraph.Header>
      <BodyParagraph>
        Depending on the destination you wish to ship to and the direction of
        the shipment a different endpoint may be required to be used when making
        the shipment request.
      </BodyParagraph>
      <BodyParagraph>
        Please see the reference table below as an aid for each scenario:
      </BodyParagraph>
      <BodyParagraph>
        <div className="markdown-table">
          <table>
            <thead>
              <tr>
                <th>API Name</th>
                <th>When to choose?</th>
                <th>Delivery Direction</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Create domestic shipment</td>
                <td>
                  Destinations: Mainland UK / Northern & Southern / Republic of
                  Ireland and Channel Islands
                </td>
                <td>Outbound services</td>
              </tr>
              <tr>
                <td>Create international shipment</td>
                <td>Destinations: EU Destinations, Non EU Destinations</td>
                <td>Outbound services</td>
              </tr>
              <tr>
                <td>Create collect on delivery shipment</td>
                <td>
                  Destinations: Mainland UK / Northern & Southern / Republic of
                  Ireland and Channel Islands
                </td>
                <td>Inbound services (Swap It / Reverse)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </BodyParagraph>
      <BodyParagraph.Header>Choosing the correct service</BodyParagraph.Header>
      <BodyParagraph>
        The Validate outbound services for address & Validate inbound services
        for address APIs should be used in order to determine service to
        postcode / zip code availability.
      </BodyParagraph>
      <BodyParagraph>
        For information relating to our UK delivery services please visit our{" "}
        <Link to={PATHS.EXTERNAL.UK_DELIVERY}>UK Delivery Services Page</Link>.
      </BodyParagraph>
      <BodyParagraph>
        For information relating to our International delivery services please
        visit our{" "}
        <Link to={PATHS.EXTERNAL.INTERNATIONAL_DELIVERY}>
          International website
        </Link>
        .
      </BodyParagraph>
    </>
  ),
};

export const INTERNATIONAL_SHIPMENT_INFORMATION = {
  summary: "International Shipment Information",
  body: (
    <>
      <BodyParagraph.Header>Invoice</BodyParagraph.Header>
      <BodyParagraph>
        The invoice object and Parcels array is required to be populated with
        required properties detailed below for International Shipments including
        EU Destinations, Non EU Destinations, Northern & Southern / Republic of
        Ireland and Channel Islands.
      </BodyParagraph>
      <BodyParagraph>
        The parcels array is required for declaring customs data using
        parcelProduct object.
      </BodyParagraph>
      <BodyParagraph>
        For the DPD Classic and DPD Direct services to Europe and the Two Day
        Service to the Republic of Ireland, parcels may only be shipped with
        Electronic Customs Data. Paper copy invoices are not to be issued.
      </BodyParagraph>
      <BodyParagraph>
        Paper copy invoices are accepted only for international Air Express and
        Air Classic services.
      </BodyParagraph>
      <MessageCard
        messageType="note"
        message="From time to time, customs may request paper copies of commercial or proforma invoices
to facilitate the clearance process. This information must be supplied by the shipper. Please
ensure you have the facility to provide/produce a paper invoice on request."
      />
      <MessageCard
        messageType="note"
        message="Product Unit Values and Customs Values provided must be exclusive of VAT."
      />
      <BodyParagraph.Header>Commodity codes</BodyParagraph.Header>
      <BodyParagraph>
        When shipping to International destinations including EU Destinations,
        Non EU Destinations, Northern & Southern / Republic of Ireland and
        Channel Islands. a valid 8 digit commodity code must be supplied for
        your products.
      </BodyParagraph>
      <BodyParagraph>
        Support in classifying products can be found at
        https://www.gov.uk/trade-tariff and the “Validate commodity code”
        reference API is available for use to determine if the given commodity
        code is valid for the given delivery country.
      </BodyParagraph>
      <BodyParagraph>
        DPD accepts no liability for any inaccuracy of a duty calculation or a
        delay or return of a parcel as a consequence of the code supplied.
      </BodyParagraph>
      <BodyParagraph.Header>IOSS (Import One-Stop Shop)</BodyParagraph.Header>
      <BodyParagraph>
        From the 1st July 2021, all commercial goods imported into the EU will
        be subject to VAT irrespective of their value. IOSS allows shippers that
        sell imported goods in the EU to collect, declare and pay the VAT to tax
        authorities instead of making the buyer pay.
        <br />
        IOSS was created to facilitate and simplify the declaration and payment
        of VAT. This is only applicable to purchases made by a buyer within the
        EU for goods values at 150 Euros or less.
        <br />
        The IOSS registration number can be declared in destinationTaxId field.
      </BodyParagraph>
    </>
  ),
};
export const LABEL_OUTPUT = {
  summary: "Label Output",
  body: (
    <>
      <BodyParagraph>
        Please be aware that we provide the print output strings in various
        formats for you to use, however the process to pass the print job to the
        relevant printer is the customer’s / third parties responsibility.
      </BodyParagraph>
      <BodyParagraph>
        When requesting label output via the “Get Label by Shipment ID” API, the
        following output variations offered consist of RAW and JSON array of
        labels.
      </BodyParagraph>
      <BodyParagraph>
        Please see the below table for reference of valid pairs combinations in
        order to retrieve label output in your required format.
      </BodyParagraph>
      <BodyParagraph>
        - printerType - request parameter advising on printer type
      </BodyParagraph>
      <BodyParagraph>
        - Accept - request header advising on the required response format
        (Applicable to Get Label by Shipment ID API)
      </BodyParagraph>
      <BodyParagraph>
        <div className="markdown-table">
          <table>
            <thead>
              <tr>
                <th>Printer Type</th>
                <th>Accept Header</th>
                <th>Output</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>0</td>
                <td>text/html</td>
                <td>Raw HTML labels</td>
              </tr>
              <tr>
                <td>0</td>
                <td>application/json</td>
                <td>JSON array of raw HTML labels</td>
              </tr>
              <tr>
                <td>1</td>
                <td>text/vnd.eltron-epl</td>
                <td>Raw EPL labels</td>
              </tr>
              <tr>
                <td>1</td>
                <td>application/json</td>
                <td>JSON array of raw EPL labels</td>
              </tr>
              <tr>
                <td>2</td>
                <td>text/vnd.citizen-clp</td>
                <td>Raw Citizen labels</td>
              </tr>
              <tr>
                <td>2</td>
                <td>application/json</td>
                <td>JSON array of raw Citizen labels</td>
              </tr>
            </tbody>
          </table>
        </div>
      </BodyParagraph>
      <BodyParagraph>Accept header explanation:</BodyParagraph>
      <BodyParagraph>
        <ul className={"list-disc"}>
          <li>
            text/vnd.citizen-clp; = Returns printer string for use with
            Citizen/TSC Thermal Printers
          </li>
          <li>
            text/vnd.eltron-epl; = Returns printer string Intended for use with
            Eltron Printer / Or certain printer models that support EPL
            emulation
          </li>
          <li>
            text/html; = Returns Plain text HTML output, intended for printing
            to A4 laser Printer on A4 stationary
          </li>
        </ul>
      </BodyParagraph>
      <MessageCard
        messageType="warning"
        message="Printing HTML output provided via API to a Thermal printer may cause label output to be scaled under minimum requirements and fail verification once label is received by ourselves and analysed, therefore is not recommended."
      />
      <MessageCard
        messageType="note"
        message="The HTML output includes padding around the label to ensure when printed to A4 stationery this is correct, in turn HTML output too large and unsuitable to be printed via thermal printer/thermal stationery."
      />
    </>
  ),
};
export const BARCODE_METRICS = {
  summary: "Barcode Metrics",
  body: (
    <>
      <BodyParagraph>
        There are important dimensional properties that must be fulfilled for
        labels to pass verification. It is highly recommended that this is
        validated prior to sending physical label examples for sign off, this is
        to assist with trying to achieve 1st time sign off when label output is
        submitted to the Customer Integration Team for sign off. Please see
        dimensions below:
      </BodyParagraph>
      <BodyParagraph>Laser/Thermal Printer</BodyParagraph>
      <BodyParagraph>
        Minimum Barcode dimensions: 91.5mm width x 25mm height
      </BodyParagraph>
      <BodyParagraph.Header>Bar-Code Quiet Zones</BodyParagraph.Header>
      <BodyParagraph>
        You must have a minimum of 5 mm quiet zone on either side of the bar
        code extending at 45 degrees from the corners. Additionally there must
        be a minimum of 1 mm on top and bottom of the entire barcode width.
      </BodyParagraph>
      <BodyParagraph.Header>
        Label Stationery Prerequisites
      </BodyParagraph.Header>
      <BodyParagraph>
        When intending to print via Thermal Printer we strongly advise label
        stationary width to measure at least 105 mm. (This is required in order
        to cater for minimum sized barcode width along with minimum quite zones
        either side of the barcode).
      </BodyParagraph>
      <BodyParagraph>
        We will not sign off any labels below 102 mm width. Please contact a
        member of the technical team if you have any further queries.
      </BodyParagraph>
      <MessageCard
        messageType="note"
        message="Use only white label stock (Direct thermal printing preferred)."
      />
    </>
  ),
};

export const ERROR_HANDLING = {
  summary: "Error Handling",
  body: (
    <>
      <MessageCard
        messageType="note"
        message="Shipping endpoints can return both authentication based errors and also shipping related errors, the response schema of Authentication related errors and Shipping related errors differ so you will need to be able to cater for both types of error responses."
      />
      <BodyParagraph>
        To see details of authentication based errors please visit our{" "}
        <Link
          to={`/${PAGES.AUTH}${PATHS.INTRODUCTION}/#${sanitisePath(AUTH_ERROR_HANDLING.summary)}`}
        >
          Authentication specification
        </Link>{" "}
        for more information.
      </BodyParagraph>
      <BodyParagraph.Header>
        Examples of common Shipping Errors
      </BodyParagraph.Header>
      <BodyParagraph>
        Please see types of examples of error codes that can be received, these
        are categorized by code/s types.
      </BodyParagraph>
      <BodyParagraph>
        <div className="markdown-table overflow-x-auto">
          <table className="min-w-full">
            <thead>
              <tr>
                <th>type</th>
                <th>code</th>
                <th>status</th>
                <th>description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>System</td>
                <td>100</td>
                <td>400</td>
                <td>System errors (e.g database error)</td>
              </tr>
              <tr>
                <td>Application</td>
                <td>101</td>
                <td>400</td>
                <td>User rule errors (e.g shipment id not found)</td>
              </tr>
              <tr>
                <td>Business</td>
                <td>102</td>
                <td>400</td>
                <td>
                  Business rule errors (e.g network is unavailable for
                  destination)
                </td>
              </tr>
              <tr>
                <td>FieldValidation</td>
                <td>103</td>
                <td>400</td>
                <td>Field validation (e.g max length exceeded)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </BodyParagraph>
      <BodyParagraph>
        Detailed error information is provided within the message field included
        within the response to describe the issue with the request.
      </BodyParagraph>
      <BodyParagraph>
        For example : "message": "Delivery country code (outbound) is mandatory"
      </BodyParagraph>
      <BodyParagraph>
        The fieldName and fieldpath also provides the affected fieldName where
        the issue has been detected and also the fieldPath gives the object /
        array on where the fieldName is located within the JSON request.
      </BodyParagraph>
      <BodyParagraph>
        "fieldName": "Delivery country code (outbound)"
      </BodyParagraph>
      <BodyParagraph>
        "fieldPath": "outboundConsignment.deliveryDetails.address.countryCode"
      </BodyParagraph>
      <JsonBlock
        value={JSON.stringify(
          {
            error: [
              {
                id: "e1a18ce6c810a0fa2aa88e804d8c3968",
                links: {
                  self: "/v1/customer/shipping/shipments/domestic",
                },
                status: 400,
                timestamp: 1707324903729,
                code: 103,
                type: "FieldValidation",
                message: "Delivery country code (outbound) is mandatory",
                fieldName: "Delivery country code (outbound)",
                fieldPath:
                  "outboundConsignment.deliveryDetails.address.countryCode",
              },
            ],
          },
          null,
          2
        )}
      />

      <BodyParagraph>
        <ul>
          <li>
            <b>System Errors (code 100):</b>
          </li>
          <ul className={"list-disc"}>
            <li>
              Using the wrong ID when performing actions on a resource (i.e get
              label for an invalid shipmentId)
            </li>
          </ul>
          <br />
          <li>
            <b>Application Errors (code 101):</b>
          </li>
          <ul className={"list-disc"}>
            <li>
              User role specific errors (e.g disabling creation of shipments via
              the customer portal)
            </li>
          </ul>
          <br />
          <li>
            <b>Business Errors (code 102):</b>
          </li>
          <ul className={"list-disc"}>
            <li>
              This account is on-stop - please contact your account manager if
              this occurs
            </li>
            <li>Missing products which describe your packages </li>
            <li>Commodity code is prohibited to destination country</li>
            <li>Invalid service/destination selected</li>
            <li>Invalid weight/number of packages</li>
          </ul>
          <br />
          <li>
            <b>Field Validation Errors (code 103):</b>
          </li>
          <ul className={"list-disc"}>
            <li>Missing mandatory fields</li>
            <li>
              Invalid dates (e.g <code>shipmentDate</code>)
            </li>
            <li>Min/Max length exceeded</li>
            <li>Min/Max value exceeded</li>
            <li>Invalid format or type</li>
          </ul>
        </ul>
        <br />
      </BodyParagraph>
    </>
  ),
};
